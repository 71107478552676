<template>
    <SimpleModalWindow
        v-if="config !== null"
        :class="$style['window']"
        :opened="opened"
        @close="close('registration')"
    >
        <template #title>
            {{ $t('auth_registration') }}
        </template>

        <SwForm ref="form" @submit.prevent="register">
            <SwFormInput
                v-if="config.can_show_field_email_for_registration"
                v-model="values.email"
                :label="$t('auth_email')"
                :class="$style['window__field']"
                :errors="errors.email"
                :readonly="isDemo"
                name="email"
                rules="required|email|email_acceptable"
                clearable
                @input="$set(errors, 'email', [])"
            />

            <SwFormInput
                v-if="config.can_show_field_password_for_registration"
                v-model="values.password"
                :label="$t('auth_password')"
                :class="$style['window__field']"
                :errors="errors.password"
                :readonly="isDemo"
                type="password"
                name="password"
                rules="required|min:2"
                clearable
                @input="$set(errors, 'password', [])"
            />

            <SwFormInput
                v-if="config.can_show_field_first_name_for_registration"
                v-model="values.firstName"
                :label="$t('auth_name')"
                :errors="errors.firstName"
                :class="$style['window__field']"
                name="firstName"
                rules="required|min:2"
                clearable
                @input="$set(errors, 'firstName', [])"
            />

            <SwFormInput
                v-if="config.can_show_field_birthday_for_registration"
                v-model="values.birthday"
                :label="$t('birthday')"
                :errors="errors.birthday"
                :class="$style['window__field']"
                name="birthday"
                rules="required|min:2|alpha_spaces"
                clearable
                @input="$set(errors, 'birthday', [])"
            />

            <SwFormInput
                v-if="config.can_show_field_phone_for_registration"
                v-model="values.phone"
                :label="$t('auth_phone')"
                :errors="errors['phone'] || []"
                :class="$style['window__field']"
                name="required|phone"
                clearable
                @input="$set(errors, 'phone', [])"
            />

            <SwButton
                :class="$style['window__button']"
                :disabled="$store.state.isLoading"
                type="submit"
                wide
            >
                {{ $t('auth_registration') }}
            </SwButton>
        </SwForm>

        <div :class="[$style['window__login'], 'sw-text']">
            {{ $t('auth_have_account') }} <a @click="openLoginModal">{{ $t('auth_login_action') }}</a>
        </div>

        <div :class="[$style['window__policy'], 'sw-text']">
            {{ $t('auth_agree_policy') }} <a @click="openPolicyModal">{{ $t('auth_agree_policy_link') }}</a>
        </div>

        <UiLoader
            v-show="$store.state.isLoading"
            fixed
            overlay
        />
    </SimpleModalWindow>
</template>

<script>
import SimpleModalWindow from '@@/website/components/common/SimpleModalWindow'
import { getValidationErrors } from '@@/website/utils/graphql'

import { REGISTER_USER } from '@@/graphQL/web'
import { isApolloError } from 'apollo-client'

export default {
  name: 'ModalRegistration',

  components: {
    SimpleModalWindow
  },

  props: {
    config: {
      type: Object,
      default: () => {
        return null
      }
    },

    opened: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      values: {
        email: '',
        phone: '',
        password: '',
        firstName: '',
        birthday: ''
      },
      errors: {}
    }
  },

  computed: {
    isDemo () {
      return !!this.$store.state.admin
    }
  },

  methods: {
    async register () {
      if (this.isDemo) {
        this.close('registration')
        this.open('forbidden')
        return
      }

      if (this.$refs.form && !(await this.$refs.form.validate())) {
        this.$notifications.error(this.$t('form_contains_errors'))
        return
      }

      try {
        this.$store.commit('lock')

        const { data } = await this.$apollo.mutate({
          mutation: REGISTER_USER,
          variables: {
            input: {
              firstName: this.values.firstName,
              email: this.values.email,
              phone: this.values.phone,
              password: this.values.password
            }
          }
        })

        await this.$store.dispatch('login', {
          user: data.registration.info,
          token: data.registration.accessToken
        })

        this.close('registration')

        if (this.$route.name === 'cart') {
          await this.$router.push({ name: 'checkout' })
        }
      } catch (e) {
        const errors = getValidationErrors(e)
        if (isApolloError(e) && errors !== undefined) {
          this.errors = errors
        } else {
          console.error(e)
        }
      } finally {
        this.$store.commit('unlock')
      }
    },

    openLoginModal () {
      this.close('registration')
      this.open('login')
    },

    openPolicyModal () {
      this.close('registration')
      this.$store.dispatch('openPublicDocument', 'document_privacy_policy')
    },

    open (name) {
      this.$store.commit('modals/open', name)
    },

    close (name) {
      this.$store.commit('modals/close', name)
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.window {
  padding: 16px 24px;
  z-index: 5000;

  @media screen and (max-width: @bp-mobile-small) {
    width: 100%;
    padding: 0;
  }

  :global(.sw-modal-window__container) {
    max-width: 360px !important;

    @media screen and (max-width: @bp-mobile-small) {
      height: 100%;
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }

  :global(.sw-panel) {
    padding: 15px 20px;
    @media screen and (max-width: @bp-mobile-small) {
      height: 100%;
      box-sizing: border-box;
    }
  }

  :global(.sw-separator) {
    min-width: calc(100% + 20px * 2);
    margin: 0 -20px 16px -20px !important;
  }

  &__field,
  &__button {
    margin-bottom: 16px;
  }

  &__login {
    .text-link;
    font-weight: normal;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;

    a {
      cursor: pointer;
    }
  }

  &__policy {
    .text-link;
    padding: 0 30px;
    text-align: center;

    &:global(.sw-text) {
      font-size: 12px;
      line-height: 16px;
      color: @grey-800;
      @media screen and (max-width: @bp-mobile-small) {
        margin-top: auto;
      }
    }

    a:hover {
      cursor: pointer;
    }
  }
}
</style>
